import { PropsWithChildren } from "react";

export function InputWithLabel({
  children,
  label,
  subLabel,
}: PropsWithChildren<{
  label: string;
  subLabel?: string;
}>) {
  return (
    <div className={"w-full"}>
      <div className={"flex flex-col"}>
        <span className={"mb-2 font-bold"}>{label}</span>
        {subLabel ? <span className={"text-sm mb-2"}>{subLabel}</span> : null}
        {children}
      </div>
    </div>
  );
}
