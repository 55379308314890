import { CalendarItemMap } from "@normasteaching/norma-ui";
import { SlotDayAndHourKey } from "./SlotDayAndHourKey";
import dayjs, { Dayjs } from "dayjs";
import {
  CalendarSlotType,
  CalendarSlotWithType,
} from "./calendarSlotWithTypes";

function resolveStartDate(slot: CalendarSlotWithType): Dayjs {
  switch (slot.type) {
    case CalendarSlotType.Availability: {
      const timezone = slot.slot.slotTimezone;
      return dayjs(slot.slot.startDateTime).tz(timezone);
    }
    case CalendarSlotType.ClassRoom: {
      const timezone = slot.slot.classRoomTimezone;
      return dayjs(slot.slot.startedAtUtc).tz(timezone);
    }
    default:
      throw new Error("Slot type not specified");
  }
}

export function convertSlotToCalendar(
  slots: CalendarSlotWithType[],
): CalendarItemMap<CalendarSlotWithType> {
  const map = new CalendarItemMap<CalendarSlotWithType>();

  slots.forEach((slot) => {
    const startDate = resolveStartDate(slot);

    const key = SlotDayAndHourKey.fromDate(dayjs(startDate));
    map.set(key, slot);
  });

  return map;
}
