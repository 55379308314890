import { SideMenu, SideMenuItem } from "@normasteaching/norma-ui";
import { useToMyCalendar } from "../../Franchisee/FranchiseeAvailability/useToMyCalendar.tsx";
import { useToExecutedClassRoomsScreen } from "../../WorkedHours/Screens/useToExecutedClassRoomsScreen.ts";
import { Logo } from "./Logo.tsx";
import { FranchiseeCalendarAvailabilityScreenPath } from "../../Franchisee/FranchiseeAvailability/FranchiseeCalendarAvailabilityScreenPath.ts";
import { ExecutedClassRoomsScreenPath } from "../../WorkedHours/Screens/ExecutedClassRoomsScreen.tsx";

export function TeacherAreaSideMenu({ activeRoute }: { activeRoute?: string }) {
  const { toMyCalendar } = useToMyCalendar();
  const { toExecutedClassRooms } = useToExecutedClassRoomsScreen();
  return (
    <SideMenu header={<Logo />}>
      <SideMenuItem
        isActive={activeRoute === FranchiseeCalendarAvailabilityScreenPath}
        label={"My Calendar"}
        onClick={toMyCalendar}
      />
      <SideMenuItem
        isActive={activeRoute === ExecutedClassRoomsScreenPath}
        label={"Executed Classrooms"}
        onClick={toExecutedClassRooms}
      />
    </SideMenu>
  );
}
