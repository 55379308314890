import { Interval } from "@normasteaching/norma-ui";
import { getFragmentData, graphql } from "../gql";
import { useQuery } from "@apollo/client";

export const GetAvailabilitySlotBetweenDates = graphql(`
  query getCurrentLoggedInTeacherAvailability(
    $maxDateString: String!
    $minDateString: String!
  ) {
    getCurrentLoggedInTeacherAvailability(
      maxDateString: $maxDateString
      minDateString: $minDateString
    ) {
      ...TeacherAvailabilitySlot
    }
  }
`);

const TeacherAvailabilitySlotFragmnet = graphql(`
  fragment TeacherAvailabilitySlot on TeacherAvailabilitySlot {
    endDateTime
    id
    startDateTime
    slotTimezone
  }
`);

export function useAvailabilityBetweenDates({
  interval,
}: {
  interval: Interval;
}) {
  const formatString = "YYYY-MM-DD";
  const { data, loading, refetch } = useQuery(GetAvailabilitySlotBetweenDates, {
    variables: {
      minDateString: interval.fromDate.format(formatString),
      maxDateString: interval.toDate.format(formatString),
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    availability:
      getFragmentData(
        TeacherAvailabilitySlotFragmnet,
        data?.getCurrentLoggedInTeacherAvailability,
      ) ?? [],
    loading,
    refetch,
  };
}
