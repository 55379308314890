import { TeacherAvailabilitySlotFragment } from "../gql/graphql";
import SvgTime from "../UI/Svgs/SvgTime";
import { formatTimeInTimezone } from "../TimeUtils/formatTimeInTimezone.ts";

type Props = {
  slot: TeacherAvailabilitySlotFragment;
};

export function AvailabilitySlotCard({ slot }: Props) {
  return (
    <>
      <div className="rounded-xl md:w-full bg-white w-[95%] hover:scale-105 transition-all ease-in-out flex justify-self-center border-t-4 border-norma-cyan">
        <div
          className="w-full flex md:gap-1 gap-3 flex-col items-start tracking-wide text-center shadow-lg py-2 px-2 rounded-xl"
          role="button"
          tabIndex={0}
        >
          <h2 className="pl-1 text-sm font-bold tracking-wider">
            Availability
          </h2>
          <div className="flex gap-2 text-xs">
            <div className="flex items-center gap-2">
              <SvgTime className="w-4 h-4 flex-shrink-0" />
              <p className="text-left">
                {formatTimeInTimezone(slot.startDateTime, slot.slotTimezone)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
