import { useMyWorkedHoursOfAMonth } from "../GraphqlQuery/useMyWorkedHoursOfAMonth.ts";
import { FullPageLoading } from "../../UI/Loading/FullPageLoading.tsx";
import { Card } from "../../UI/Card.tsx";
import dayjs from "dayjs";
import { WorkedHourFragment } from "../../gql/graphql.ts";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { BreadCrumbsItem } from "../../UI/Breadcrumb/BreadCrumbsItem.tsx";
import { BreadCrumbsWrapper } from "../../UI/Breadcrumb/BreadCrumbsWrapper.tsx";
import { useToExecutedClassRoomsScreen } from "./useToExecutedClassRoomsScreen.ts";
import { InfoFeedback } from "../../UI/Feedback/InfoFeedback.tsx";
import { useMonthStartDateOrThrow } from "./useMonthStartDateOrThrow.tsx";
import { Layout, LayoutCtxProvider } from "@normasteaching/norma-ui";
import { NavBarWithLoggedUser } from "../../UI/Layout/NavBarWithLoggedUser.tsx";
import { TeacherAreaSideMenu } from "../../UI/Layout/TeacherAreaSideMenu.tsx";

export const MonthExecutedClassRoomScreenPath =
  "/month-executed-classrooms/:monthStartDate";

export function MonthExecutedClassRoomScreen() {
  const { monthStartDate } = useMonthStartDateOrThrow();
  const { toExecutedClassRooms } = useToExecutedClassRoomsScreen();
  const { workedHours, loading } = useMyWorkedHoursOfAMonth(
    new Date(monthStartDate),
  );

  function formatDate(startDate: string, endDate: string) {
    return `${dayjs(startDate).format("ddd DD MMM YYYY")} from ${dayjs(
      startDate,
    ).format("HH:mm")} to ${dayjs(endDate).format("HH:mm")}`;
  }

  function calculateTotalInCents(workedHours: WorkedHourFragment[]): number {
    return workedHours.reduce((acc, wh) => {
      return acc + wh.hourlyWageInCents;
    }, 0);
  }

  if (loading) return <FullPageLoading />;

  const title = `Executed classrooms on ${dayjs(monthStartDate).format("MMMM YYYY")}`;

  return (
    <LayoutCtxProvider>
      <Layout
        navBar={<NavBarWithLoggedUser />}
        sideMenu={<TeacherAreaSideMenu />}
      >
        <div className={"space-y-4 p-4"}>
          <Card>
            <ScreenTitleBlock
              hideLineDivider
              title={title}
              breadCrumbs={
                <BreadCrumbsWrapper>
                  <BreadCrumbsItem
                    onClick={() => toExecutedClassRooms()}
                    title={"Executed classrooms"}
                  />
                  <BreadCrumbsItem title={title} />
                </BreadCrumbsWrapper>
              }
            />
          </Card>
          <Card>
            <div className={"space-y-2"}>
              {workedHours.length > 0 ? (
                workedHours.map((wh) => {
                  return (
                    <div
                      className={
                        "border border-slate-100 rounded-lg p-3 space-x-2 flex items-center justify-between"
                      }
                      key={wh.id}
                    >
                      <span>{formatDate(wh.startDateUtc, wh.endDateUtc)}</span>
                      <span className={"font-bold"}>
                        {wh.hourlyWageInCents / 100} €
                      </span>
                    </div>
                  );
                })
              ) : (
                <div>
                  <InfoFeedback
                    title={"No Executed Classrooms This Month"}
                    message={
                      "📅 No classrooms have been executed this month. It seems there were no activities carried out."
                    }
                  />
                </div>
              )}
            </div>
          </Card>
          <Card>
            <div className={"flex items-center space-x-2 justify-between p-3"}>
              <span>Taxable total</span>
              <span className={"font-bold"}>
                {calculateTotalInCents(workedHours) / 100} €
              </span>
            </div>
          </Card>
        </div>
      </Layout>
    </LayoutCtxProvider>
  );
}
