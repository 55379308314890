import { graphql } from "../../gql";

export const WorkedHourFragment = graphql(`
  fragment WorkedHour on WorkedHour {
    id
    startDateUtc
    endDateUtc
    hourlyWageInCents
  }
`);
