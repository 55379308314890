import { Layout, NavBar } from "@normasteaching/norma-ui";
import { ErrorFeedback } from "../UI/Feedback/ErrorFeedback.tsx";
import { useAuthCtx } from "./useAuthCtx.ts";

export function MissingTeacherScreen() {
  const { signOut } = useAuthCtx();
  return (
    <Layout>
      <NavBar
        right={
          <div className={"justify-end text-black underline cursor-pointer"}>
            <a
              onClick={(e) => {
                e.preventDefault();
                signOut();
              }}
            >
              Log Out
            </a>
          </div>
        }
      />
      <div className={"flex-1 flex items-center justify-center"}>
        <div className={"max-w-[400px]"}>
          <ErrorFeedback
            title={"You're not a teacher"}
            message={
              "Your account does not appear to be linked to a teacher profile. If you believe this is a mistake, please reach out to our support team for assistance."
            }
          />
        </div>
      </div>
    </Layout>
  );
}
