import { getFragmentData, graphql } from "../../gql";
import { WorkedHourFragment } from "./WorkedHourFragment.ts";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";

const GET_MY_WORKED_HOURS_OF_A_MONTH_QUERY = graphql(`
  query getMyWorkedHoursOfAMonth(
    $clientTimezone: String!
    $monthStartDateTimeUtc: String!
  ) {
    getMyWorkedHoursOfAMonth(
      clientTimezone: $clientTimezone
      monthStartDateTimeUtc: $monthStartDateTimeUtc
    ) {
      ...WorkedHour
    }
  }
`);
export function useMyWorkedHoursOfAMonth(monthStartDateTimeUtc: Date) {
  const { data, loading, error } = useQuery(
    GET_MY_WORKED_HOURS_OF_A_MONTH_QUERY,
    {
      variables: {
        clientTimezone: dayjs.tz.guess(),
        monthStartDateTimeUtc: monthStartDateTimeUtc.toISOString(),
      },
    },
  );

  return {
    workedHours:
      getFragmentData(WorkedHourFragment, data?.getMyWorkedHoursOfAMonth) ?? [],
    loading,
    error,
  };
}
