import { Select, SelectOption } from "../Select.tsx";
import { isDefined } from "../../isDefined.ts";

export function YearInput({
  value,
  onChange,
  startYear,
  endYear,
}: {
  value: number;
  onChange: (value: number) => void;
  startYear: number;
  endYear: number;
}) {
  function createOptions(
    startYear: number,
    endYear: number,
  ): SelectOption<number>[] {
    const options: SelectOption<number>[] = [];
    for (let year = startYear; year <= endYear; year++) {
      options.push({ name: year.toString(), id: year });
    }
    return options;
  }

  return (
    <Select<number>
      value={value}
      options={createOptions(startYear, endYear)}
      onChange={(v) => {
        if (!isDefined(v)) return;
        onChange(v);
      }}
    />
  );
}
