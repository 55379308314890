import { graphql } from "../../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CreateRecurringAvailabilitySlotInput } from "../../gql/graphql.ts";

const CreateRecurringAvailabilitySlot = graphql(`
  mutation Mutation($input: CreateRecurringAvailabilitySlotInput!) {
    createRecurringAvailabilitySlot(input: $input) {
      endDateTime
      id
      startDateTime
    }
  }
`);

export function useCreateRecurringAvailabilitySlot() {
  const [createRecurringAvailabilitySlot, { loading }] = useMutation(
    CreateRecurringAvailabilitySlot,
  );

  const createRecurringAvailabilitySlotMutation = useCallback(
    async (input: { input: CreateRecurringAvailabilitySlotInput }) => {
      try {
        const response = await createRecurringAvailabilitySlot({
          variables: input,
        });
        return response?.data?.createRecurringAvailabilitySlot;
      } catch (err) {
        console.error("Error creating availability slot:", err);
        throw err;
      }
    },
    [createRecurringAvailabilitySlot],
  );

  return {
    createRecurringAvailabilitySlotMutation,
    loading,
  };
}
