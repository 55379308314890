import { Link } from "react-router-dom";
import { FranchiseeCalendarAvailabilityScreenPath } from "./FranchiseeCalendarAvailabilityScreenPath.ts";

export function GiveAvailabilityThankYouModal({
  showThankYouModal,
}: {
  showThankYouModal: boolean;
}) {
  return showThankYouModal ? (
    <div className="fixed z-[9999] w-screen h-screen bg-black/40 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md shadow-xl flex flex-col justify-center items-center">
        <h2 className="text-lg font-bold mb-4">
          Thank you for providing your availability.
        </h2>
        <Link
          to={FranchiseeCalendarAvailabilityScreenPath}
          className="bg-primary text-white px-4 py-2 rounded-md"
        >
          BACK TO CALENDAR
        </Link>
      </div>
    </div>
  ) : null;
}
