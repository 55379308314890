import { Dayjs } from "dayjs";

export class YearReference {
  protected constructor(
    public readonly startYearDate: Dayjs,
    public readonly endYearDate: Dayjs,
  ) {}

  public static fromDayjs(date: Dayjs): YearReference {
    return new YearReference(date.startOf("year"), date.endOf("year"));
  }
}
