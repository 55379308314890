import { useNavigate } from "react-router-dom";
import { ExecutedClassRoomsScreenPath } from "./ExecutedClassRoomsScreen.tsx";

export function useToExecutedClassRoomsScreen() {
  const navigate = useNavigate();

  const toExecutedClassRooms = () => {
    navigate(ExecutedClassRoomsScreenPath);
  };

  return {
    toExecutedClassRooms,
  };
}
