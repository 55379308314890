import dayjs from "dayjs";
import { isDifferentFromLocalTimezone } from "./isDifferentFromLocalTimezone.ts";

function getFormatHourString(slotTimezone: string) {
  return isDifferentFromLocalTimezone(slotTimezone) ? "hh:mm A z" : "hh:mm A";
}

export function formatTimeInTimezone(
  date: string,
  slotTimezone: string,
): string {
  const parts: string[] = [];
  parts.push(
    dayjs(date).tz(slotTimezone).format(getFormatHourString(slotTimezone)),
  );
  if (isDifferentFromLocalTimezone(slotTimezone))
    parts.push(`(${dayjs(date).format("hh:mm A")} in your local time)`);
  parts.push("| 1 hour");
  return parts.join(" ");
}
