import { Time, TimePicker } from "@normasteaching/norma-ui";
import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import SvgTime from "../Svgs/SvgTime.tsx";
import { isDifferentFromLocalTimezone } from "../../TimeUtils/isDifferentFromLocalTimezone.ts";
import { InfoFeedback } from "../Feedback/InfoFeedback.tsx";
import dayjs from "dayjs";

function LocalTimeIfNeededRenderer({
  slotTimezone,
  time,
  date,
}: {
  slotTimezone: string;
  time: Time;
  date: Date;
}) {
  if (!isDifferentFromLocalTimezone(slotTimezone)) return null;
  return (
    <p className={"text-slate-500 text-sm"}>
      {dayjs
        .tz(
          `${dayjs(date).format("YYYY-MM-DD")}T${time.toString()}`,
          slotTimezone,
        )
        .tz(dayjs.tz.guess())
        .format("HH:mm")}{" "}
      in your local time
    </p>
  );
}

function TimePickerContainer({ children }: PropsWithChildren) {
  return <div className={"space-y-1"}>{children}</div>;
}

export function TimeInputsRow({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  date,
  slotTimezone,
}: {
  startTime: Time;
  setStartTime: Dispatch<SetStateAction<Time>>;
  endTime: Time;
  setEndTime: Dispatch<SetStateAction<Time>>;
  date: Date;
  slotTimezone: string;
}) {
  const maxHourFrom = 21;
  const maxHourTo = 22;

  const handleStartTimeChange = (newTime: Time) => {
    setStartTime(newTime);

    if (Time.isAfter(newTime, endTime)) {
      const newEndTimeHour = Math.min(newTime.hour + 1, maxHourTo);
      setEndTime(new Time(newEndTimeHour, newTime.minute, newTime.second));
    }
  };

  const handleEndTimeChange = (newEndTime: Time) => {
    setEndTime(newEndTime);
  };

  const endTimeFromHour = Math.min(startTime.hour + 1, maxHourTo);

  return (
    <div className={"space-y-3"}>
      {isDifferentFromLocalTimezone(slotTimezone) ? (
        <div>
          <InfoFeedback
            title={"Note"}
            message={"Entered times should be in CET (Central European Time)."}
          />
        </div>
      ) : null}
      <div className="text-center grid grid-cols-2 gap-4 text-sm font-semibold">
        <div className="flex justify-center items-center gap-2">
          <p>From</p>
          <SvgTime className="w-4 h-4" />
        </div>
        <div className="flex justify-center items-center gap-2">
          <p>To</p>
          <SvgTime className="w-4 h-4" />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 items-center">
        <TimePickerContainer>
          <TimePicker
            onChange={handleStartTimeChange}
            value={startTime}
            hourFromTo={{ from: 9, to: maxHourFrom }}
          />
          <LocalTimeIfNeededRenderer
            time={startTime}
            date={date}
            slotTimezone={slotTimezone}
          />
        </TimePickerContainer>
        <TimePickerContainer>
          <TimePicker
            onChange={handleEndTimeChange}
            value={endTime}
            hourFromTo={{
              from: endTimeFromHour,
              to: maxHourTo,
            }}
          />
          <LocalTimeIfNeededRenderer
            time={endTime}
            date={date}
            slotTimezone={slotTimezone}
          />
        </TimePickerContainer>
      </div>
    </div>
  );
}
