import dayjs from "dayjs";
import { isDifferentFromLocalTimezone } from "./isDifferentFromLocalTimezone.ts";

function getFormatDateString(timezone: string): string {
  return isDifferentFromLocalTimezone(timezone)
    ? "dddd, MMMM DD, YYYY [at] hh:mm A z"
    : "dddd, MMMM DD, YYYY [at] hh:mm A";
}

export function formatDateInTimezone(
  date: string,
  slotTimezone: string,
): string {
  const parts: string[] = [];
  parts.push(
    dayjs(date).tz(slotTimezone).format(getFormatDateString(slotTimezone)),
  );
  if (isDifferentFromLocalTimezone(slotTimezone))
    parts.push(`(${dayjs(date).format("hh:mm A")} in your local time)`);
  parts.push("| 1 hour");
  return parts.join(" ");
}
