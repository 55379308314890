import { TablePicker, TablePickerMobile } from "@normasteaching/norma-ui";
import { Layout } from "../../UI/Layout/Layout.tsx";
import { useIsMobile } from "../../UI/Utils/useIsMobile.ts";
import { useState } from "react";
import { useCreateOneHourAvailabilitySlot } from "./useCreateOneHourAvailabilitySlot.ts";
import { useAlreadyReadInstructionsToGiveAvailabilityOnce } from "./useAlreadyReadInstructionsToGiveAvailabilityOnce.ts";
import { GiveAvailabilityInstructionModalComponent } from "./GiveAvailabilityInstructionModalComponent.tsx";
import { GiveAvailabilityThankYouModal } from "./GiveAvailabilityThankYouModal.tsx";
import { ErrorMessagePopup } from "../../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../../UI/MessagePopup/useMessagePopupController.ts";
import dayjs from "dayjs";

type Slot = {
  start: string;
  end: string;
  date: Date;
};

export function GiveAvailabilitiesScreen() {
  const { alreadyReadInstructions, markInstructionsAsAlreadyRead } =
    useAlreadyReadInstructionsToGiveAvailabilityOnce();
  const {
    show: showError,
    showPopup: showErrorPopup,
    title,
    description,
  } = useMessagePopupController(5 * 1000);
  const [instructionsModalOpened, setInstructionsModalOpened] =
    useState<boolean>(!alreadyReadInstructions);

  const [showThankYouModal, setShowThankYouModal] = useState<boolean>(false);

  const [selectedSlots, setSelectedSlots] = useState<Slot[]>([]);
  const [checkStates, setCheckStates] = useState<{ [key: string]: boolean }>(
    {},
  );
  const { createOneHourAvailabilitySlots, loading } =
    useCreateOneHourAvailabilitySlot();

  const slotTimezone = "Europe/Rome";

  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

  const isMobile = useIsMobile();

  const fixTimeFormat = (time: string) => {
    if (time.length === 4) {
      return `0${time}`;
    }
    return time;
  };

  const handleSubmit = async () => {
    const isoSlots = selectedSlots.map((slot) => {
      const datePart = slot.date.toISOString().split("T")[0];

      const correctedStart = fixTimeFormat(slot.start);
      const correctedEnd = fixTimeFormat(slot.end);
      const startISO = dayjs
        .tz(`${datePart}T${correctedStart}:00`, slotTimezone)
        .toISOString();
      const endISO = dayjs
        .tz(`${datePart}T${correctedEnd}:00`, slotTimezone)
        .toISOString();

      return { startDateTime: startISO, endDateTime: endISO };
    });

    try {
      for (const isoSlot of isoSlots) {
        await createOneHourAvailabilitySlots({
          input: {
            startDateTime: isoSlot.startDateTime,
            timezone: slotTimezone,
            endDateTime: isoSlot.endDateTime,
          },
        });
      }
      setShowThankYouModal(true);
    } catch (error) {
      showErrorPopup(
        `Ops!`,
        `Something went wrong during slot creation: ${(error as Error)?.message ?? "Unknown error"}`,
      );
      throw error;
    }
  };
  const toggleInstructionsModalOpenState = () => {
    markInstructionsAsAlreadyRead();
    setInstructionsModalOpened((v) => !v);
  };

  const displayedHours = Array.from({ length: 13 }, (_, i) => 9 + i);

  return (
    <>
      <ErrorMessagePopup
        title={title}
        description={description}
        show={showError}
      />
      <GiveAvailabilityInstructionModalComponent
        instructionsModalOpened={instructionsModalOpened}
        toggleInstructionsModalOpenState={toggleInstructionsModalOpenState}
      />

      <GiveAvailabilityThankYouModal showThankYouModal={showThankYouModal} />

      <Layout>
        {isMobile ? (
          <TablePickerMobile
            handleSubmit={handleSubmit}
            onClickInfo={toggleInstructionsModalOpenState}
            onClick={() => {}}
            monthDisplayed={nextMonth}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
            checkStates={checkStates}
            setCheckStates={setCheckStates}
            disabled={selectedSlots.length === 0}
            loading={loading}
            displayedHours={displayedHours}
            tableTimezone={slotTimezone}
          />
        ) : (
          <TablePicker
            handleSubmit={handleSubmit}
            onClickInfo={toggleInstructionsModalOpenState}
            onClick={() => {}}
            monthDisplayed={nextMonth}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
            checkStates={checkStates}
            setCheckStates={setCheckStates}
            disabled={selectedSlots.length === 0}
            loading={loading}
            displayedHours={displayedHours}
            tableTimezone={slotTimezone}
          />
        )}
      </Layout>
    </>
  );
}
