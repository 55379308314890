import { Link } from "react-router-dom";
import { ClassRoomSlotCardsProp } from "./ClassRoomSlotCardsProp";
import { useControlCalendarCardModal } from "./useControlCalendarModal";
import SvgTime from "../UI/Svgs/SvgTime";
import { SvgParticipant } from "../UI/Svgs/SvgParticipant";
import { CalendarDaysIcon, LinkIcon } from "@heroicons/react/24/outline";
import { SvgShield } from "../UI/Svgs/SvgShield";
import { SvgBook } from "../UI/Svgs/SvgBook";
import { SvgMeet } from "../UI/Svgs/SvgMeet";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
} from "@headlessui/react";
import { AcademicCapIcon } from "@heroicons/react/16/solid";
import { formatTimeInTimezone } from "../TimeUtils/formatTimeInTimezone.ts";
import { formatDateInTimezone } from "../TimeUtils/formatDateInTimezone.ts";

export function SpeakingClassSlotCard({ slot }: ClassRoomSlotCardsProp) {
  const { showModal, onClose, onOpen } = useControlCalendarCardModal(slot.id);

  return (
    <>
      <div
        onClick={onOpen}
        className="relative rounded-xl md:w-full text-sm w-[95%] hover:scale-105 transition-all ease-in-out flex justify-self-center border-t-4 border-t-norma-purple bg-white"
      >
        <div
          className="w-full flex md:gap-1 gap-2 flex-col items-start text-center tracking-wide rounded-xl shadow-lg py-2"
          role="button"
          tabIndex={0}
        >
          <p className="text-sm text-center font-extrabold w-full">RLC</p>
          <div className="w-[80%] border-t-[1px] border-black/10 mx-auto"></div>
          <p className="font-bold tracking-wider text-xs text-center px-2 py-1 w-full">
            {slot.topic.name}
          </p>
          <div className="w-[80%] border-t-[1px] border-black/10 mx-auto"></div>
          <div className="flex justify-center items-center gap-2 pl-2">
            <SvgTime className="w-4 h-4 flex-shrink-0" />
            <p className="text-left">
              {formatTimeInTimezone(slot.startedAtUtc, slot.classRoomTimezone)}
            </p>
          </div>
          <div className="flex justify-center items-center gap-2 pl-2">
            <SvgParticipant className="w-4 h-4" />
            <p className="text-sm">{slot.participantsCount} / 6</p>
          </div>
        </div>
      </div>
      <Transition
        show={showModal}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          open={showModal}
          onClose={onClose}
          className="fixed inset-0 overflow-y-auto data-[closed]:opacity-0"
          transition
          unmount={false}
        >
          <DialogBackdrop className="fixed inset-0 bg-gray-500/40" transition />
          <div className="flex items-center justify-center min-h-screen">
            <DialogPanel
              transition
              className="transition-all duration-300 ease-in-out data-[closed]:opacity-0 data-[closed]:scale-95 py-6 z-10 px-10 w-[80%] md:w-[35%] md:h-[40%] h-[45%] shadow-lg bg-white flex flex-col rounded-2xl border-t-8 border-norma-purple"
            >
              <div className="w-full h-full flex flex-col">
                <p className="text-center text-xl pl-5 font-semibold">
                  REAL LIFE CONVERSATION
                </p>
                <div className="flex justify-start items-center">
                  <AcademicCapIcon className="w-5 h-5 flex-shrink-0" />
                  <p className="py-4 text-lg pl-3 font-semibold tracking-wider">
                    topic: {slot.topic.name}
                  </p>
                </div>
                <div className="flex flex-col w-full h-full gap-1">
                  <div className="flex items-center gap-2">
                    <CalendarDaysIcon className="w-5 h-5 flex-shrink-0" />
                    <p>
                      {formatDateInTimezone(
                        slot.startedAtUtc,
                        slot.classRoomTimezone,
                      )}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <SvgShield className={"w-5 h-5 flex-shrink-0"} />
                    <p>{slot.topic.level}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <SvgParticipant className="w-5 h-5 flex-shrink-0" />
                    <p>{slot.participantsCount} / 6</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <SvgBook className={"w-5 h-5 flex-shrink-0"} />
                    <Link
                      to={slot.topic.pdfUrl}
                      className="underline underline-offset-2"
                    >
                      View PDF
                    </Link>
                  </div>
                  <div className="flex items-center gap-2">
                    <LinkIcon className="w-5 h-5 flex-shrink-0" />
                    <Link
                      to={slot.feedbackUri}
                      className="underline underline-offset-2"
                    >
                      Feedback
                    </Link>
                    /
                    <Link
                      to={slot.feedbackReportUri}
                      className="underline underline-offset-2"
                    >
                      Report
                    </Link>
                  </div>

                  <div className="flex items-center gap-2">
                    <SvgMeet className={"w-5 h-5 flex-shrink-0"} />
                    <Link
                      to={slot.meetingUri}
                      className="underline underline-offset-2"
                    >
                      Link to Google Meet
                    </Link>
                  </div>
                </div>
                <div className="w-full flex justify-center">
                  <button
                    className="text-center font-semibold underline text-gray-600 hover:text-black p-4"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
