import { useNavigate } from "react-router-dom";
import { MonthExecutedClassRoomScreenPath } from "./MonthExecutedClassRoomScreen.tsx";

export function useNavigateToMonthExecutedClassroomsScreen() {
  const navigate = useNavigate();
  const toMonthExecutedClassRoomsScreen = (monthStartDate: string) => {
    navigate(
      MonthExecutedClassRoomScreenPath.replace(
        ":monthStartDate",
        monthStartDate,
      ),
    );
  };

  return {
    toMonthExecutedClassRoomsScreen,
  };
}
