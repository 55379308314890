import { useNavigate } from "react-router-dom";
import { FranchiseeCalendarAvailabilityScreenPath } from "./FranchiseeCalendarAvailabilityScreenPath.ts";

export function useToMyCalendar() {
  const navigate = useNavigate();
  const toMyCalendar = () => {
    navigate(FranchiseeCalendarAvailabilityScreenPath);
  };

  return {
    toMyCalendar,
  };
}
