import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { ClassRoomTeacher } from "../gql/graphql";
import { useAuthCtx } from "./useAuthCtx";
import { useHandleRedirectToOldUrl } from "./useHandleRedirectToOldUrl";
import { LoginScreenPath } from "./LoginScreen";
import { MissingTeacherScreen } from "./MissingTeacherScreen.tsx";

type Props = PropsWithChildren<{
  onUserRetrieved?: (user: ClassRoomTeacher) => void;
}>;

export const ProtectedRoute = ({ children }: Props) => {
  const { authTeacher, teacher: user } = useAuthCtx();

  const { getUrlWithRedirect } = useHandleRedirectToOldUrl();

  if (!authTeacher) {
    const redirectTo = getUrlWithRedirect(
      new URL(LoginScreenPath, window.location.origin),
    );
    return (
      <Navigate
        to={`${LoginScreenPath}?${redirectTo.searchParams.toString()}`}
        replace
      />
    );
  }

  if (!user) return <MissingTeacherScreen />;

  return children ?? null;
};
