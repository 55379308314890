import { useMyWorkedHoursByMonth } from "../GraphqlQuery/useMyWorkedHoursByMonth.ts";
import { YearReference } from "../GraphqlQuery/YearReference.ts";
import dayjs from "dayjs";
import {
  Layout,
  LayoutCtxProvider,
  SpinnerContainer,
} from "@normasteaching/norma-ui";
import { useState } from "react";
import { Card } from "../../UI/Card.tsx";
import { classNames } from "../../UI/Utils/classNames.ts";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { YearInput } from "../../UI/Input/YearInput.tsx";
import { InputWithLabel } from "../../UI/InputWithLabel.tsx";
import { useNavigateToMonthExecutedClassroomsScreen } from "./useNavigateToMonthExecutedClassroomsScreen.tsx";
import { NavBarWithLoggedUser } from "../../UI/Layout/NavBarWithLoggedUser.tsx";
import { TeacherAreaSideMenu } from "../../UI/Layout/TeacherAreaSideMenu.tsx";

export const ExecutedClassRoomsScreenPath = "/executed-classrooms";

export function ExecutedClassRoomsScreen() {
  const [year, setYear] = useState(dayjs().year());
  const { toMonthExecutedClassRoomsScreen } =
    useNavigateToMonthExecutedClassroomsScreen();
  function getDateFromYear(year: number) {
    return dayjs().year(year);
  }

  const { workedHoursByMonth, loading } = useMyWorkedHoursByMonth({
    yearReference: YearReference.fromDayjs(getDateFromYear(year)),
  });

  const startYear = 2023;
  const endYear = dayjs().year();

  function toMonth(monthStartDate: Date) {
    toMonthExecutedClassRoomsScreen(monthStartDate.toISOString());
  }
  return (
    <LayoutCtxProvider>
      <Layout
        navBar={<NavBarWithLoggedUser />}
        sideMenu={
          <TeacherAreaSideMenu activeRoute={ExecutedClassRoomsScreenPath} />
        }
      >
        <div className={"space-y-4 p-4"}>
          <Card>
            <ScreenTitleBlock hideLineDivider title={`Executed classrooms`} />
          </Card>
          <Card>
            <InputWithLabel label={"Year"}>
              <YearInput
                startYear={startYear}
                endYear={endYear}
                value={year}
                onChange={setYear}
              />
            </InputWithLabel>
          </Card>
          <SpinnerContainer loading={loading}>
            <Card>
              <div className={"space-y-2"}>
                {workedHoursByMonth.map((workedHours) => (
                  <div
                    onClick={() =>
                      toMonth(new Date(workedHours.startOfMonthDate))
                    }
                    className={classNames(
                      "border border-slate-100 p-3",
                      "p-1",
                      "hover:bg-primary",
                      "hover:text-white",
                      "cursor-pointer",
                      "rounded-lg",
                    )}
                    key={workedHours.startOfMonthDate.toString()}
                  >
                    <div
                      className={"flex items-center justify-between space-x-2"}
                    >
                      <span>
                        {dayjs(workedHours.startOfMonthDate).format("MMMM")}
                      </span>
                      <span>
                        {workedHours.totalTaxableAmountInCents / 100}€
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </SpinnerContainer>
        </div>
      </Layout>
    </LayoutCtxProvider>
  );
}
