import { graphql } from "../../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CreateOneHourAvailabilitySlotInput } from "../../gql/graphql.ts";

const CreateOneHourAvailabilitySlot = graphql(`
  mutation CreateOneHourAvailabilitySlot(
    $input: CreateOneHourAvailabilitySlotInput!
  ) {
    createOneHourAvailabilitySlot(input: $input) {
      endDateTime
      id
      startDateTime
    }
  }
`);

export function useCreateOneHourAvailabilitySlot() {
  const [createOneHourAvailabilitySlot, { loading }] = useMutation(
    CreateOneHourAvailabilitySlot,
  );

  const createOneHourAvailabilitySlots = useCallback(
    async (input: { input: CreateOneHourAvailabilitySlotInput }) => {
      try {
        const response = await createOneHourAvailabilitySlot({
          variables: input,
        });
        return response?.data?.createOneHourAvailabilitySlot;
      } catch (err) {
        console.error("Error creating availability slot:", err);
        throw err;
      }
    },
    [createOneHourAvailabilitySlot],
  );

  return {
    createOneHourAvailabilitySlots,
    loading,
  };
}
