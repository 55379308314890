import dayjs from "dayjs";
import { getFragmentData, graphql } from "../../gql";
import { useQuery } from "@apollo/client";
import { WorkedHoursByMonthItemFragment } from "./WorkedHoursByMonthItemFragment.ts";
import { YearReference } from "./YearReference.ts";

const GET_MY_WORKED_HOURS_BY_MONTH_QUERY = graphql(`
  query myWorkedHoursByMonth(
    $clientTimezone: String!
    $startDateUtc: String!
    $endDateUtc: String!
  ) {
    myWorkedHoursByMonth(
      clientTimezone: $clientTimezone
      startDateUtc: $startDateUtc
      endDateUtc: $endDateUtc
    ) {
      ...WorkedHoursByMonthItem
    }
  }
`);
export function useMyWorkedHoursByMonth({
  yearReference,
}: {
  yearReference: YearReference;
}) {
  const { data, loading, error } = useQuery(
    GET_MY_WORKED_HOURS_BY_MONTH_QUERY,
    {
      variables: {
        startDateUtc: yearReference.startYearDate.toISOString(),
        endDateUtc: yearReference.endYearDate.toISOString(),
        clientTimezone: dayjs.tz.guess(),
      },
    },
  );

  return {
    workedHoursByMonth:
      getFragmentData(
        WorkedHoursByMonthItemFragment,
        data?.myWorkedHoursByMonth,
      ) ?? [],
    loading,
    error,
  };
}
