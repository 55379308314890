import { useState } from "react";

export function useAlreadyReadInstructionsToGiveAvailabilityOnce() {
  const storageKey = "alreadyReadInstructionsToGiveAvailabilityOnce";
  function getFromStorage(): boolean {
    return localStorage.getItem(storageKey) === "true";
  }

  function saveInStorage(val: boolean): void {
    localStorage.setItem(storageKey, val.toString());
  }

  const [alreadyReadInstructions, setAlreadyReadInstructions] =
    useState(getFromStorage());

  function markInstructionsAsAlreadyRead() {
    saveInStorage(true);
    setAlreadyReadInstructions(true);
  }

  return {
    alreadyReadInstructions,
    markInstructionsAsAlreadyRead,
  };
}
