import { useEffect, useRef, useState } from "react";
import { SvgClose } from "../../UI/Svgs/SvgClose.tsx";
import { PrimaryButton } from "../../UI/PrimaryButton.tsx";
import { ArrowDownCircleIcon } from "@heroicons/react/16/solid";

export function GiveAvailabilityInstructionModalComponent({
  toggleInstructionsModalOpenState,
  instructionsModalOpened,
}: {
  toggleInstructionsModalOpenState: () => void;
  instructionsModalOpened: boolean;
}) {
  const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false);
  const instructionsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = instructionsRef.current;
    if (!el) return;

    const onScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = el;
      setShowScrollArrow(scrollTop + clientHeight < scrollHeight - 50);
    };

    onScroll();

    el.addEventListener("scroll", onScroll);
    return () => {
      el.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <div
      className={`fixed z-[9999] w-screen h-screen bg-black/40 flex justify-center items-center ${
        instructionsModalOpened ? "flex" : "hidden"
      }`}
    >
      <div
        ref={instructionsRef}
        className="relative shadow-2xl rounded-xl bg-white w-[90%] md:w-[30%] h-[60%] md:h-[90%] m-4 z-[100] overflow-auto p-4 flex flex-col gap-6"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <div className="absolute top-0 right-0 p-4 cursor-pointer">
          <SvgClose
            className="w-4 h-4 stroke-black"
            onClick={toggleInstructionsModalOpenState}
          />
        </div>
        <h2 className="text-lg font-bold tracking-wide underline-offset-2 underline">
          ISTRUCTIONS FOR ADDING AVAILABILITY SLOTS
        </h2>
        <p className="text-lg font-semibold">Hello Teachers! 😊</p>
        <p>
          Here’s a quick guide to help you add your availability slots into the
          scheduling table for the upcoming month. Let's make it easy and
          smooth!
        </p>
        <hr />
        <h3 className="font-semibold text-xl">Step-by-Step Instructions:</h3>
        <ol className="list-decimal list-inside space-y-2 flex flex-col gap-6">
          <li>
            <strong>Access the Scheduling Table</strong>
            <br />
            Open the table for the{" "}
            <span className="font-semibold">next month</span> and review the
            layout. You’ll see the days of the month along with corresponding
            time slots.
          </li>
          <li>
            <strong>Enter Your Availability</strong>
            <br />
            Click on the cells that correspond to the dates and times when
            you’re available. Add your availability by filling in the slots.
            <br />
            <p className="text-xs py-2">
              <em>Tip:</em> Double-check the times to ensure accuracy—it’s
              better to be sure now than to fix it later! 😅
            </p>
          </li>
          <li>
            <strong>Verify Your Entries</strong>
            <br />
            Before you finish, take a moment to review all the slots you’ve
            selected:
            <ul className="list-disc list-inside ml-4 text-sm py-2 flex flex-col gap-1">
              <li>Are the days correct?</li>
              <li>Do the times match your availability?</li>
            </ul>
            A quick final check can save a lot of hassle for everyone later! ✅
          </li>
          <li>
            <strong>Scroll to the End</strong>
            <br />
            Remember to{" "}
            <span className="font-semibold">
              scroll all the way to the last week of the month
            </span>
            . Sometimes, those final days get overlooked!
          </li>
          <li className="mb-8">
            <strong>Submit the Form</strong>
            <br />
            Once you’ve entered and verified all your slots, hit the{" "}
            <span className="font-semibold">Submit</span> button. 🎉
          </li>
        </ol>
        <h3>Friendly Tips:</h3>
        <ul className="list-disc list-inside ml-4 text-sm">
          <li>
            If you’re unsure about a specific slot, reach out for clarification
            before submitting.
          </li>
          <li>
            Keep your entries clear and concise so the scheduling process is
            smooth for everyone.
          </li>
        </ul>
        <p className="text-sm">
          Thank you for your time and effort — It makes a big difference! Let’s
          make the schedule work for everyone.{" "}
          <span className="text-xs">😊</span>
        </p>
        <p className="text-sm">
          If you have any questions, feel free to ask for help. We're here to
          support you! <span className="text-xs">💬</span>
        </p>
        <div className="w-full flex justify-center">
          <PrimaryButton
            label="CLOSE"
            onClick={toggleInstructionsModalOpenState}
          />
        </div>

        {showScrollArrow && (
          <div className="p-2 text-white rounded-full w-full animate-bounce h-auto sticky bottom-0 right-0">
            <div className="absolute right-0 bottom-0 rounded-full bg-black/40 w-10 h-10 flex justify-center items-center">
              <ArrowDownCircleIcon className="w-5 h-5" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
