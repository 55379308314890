import { useParams } from "react-router-dom";

export function useMonthStartDateOrThrow() {
  const { monthStartDate } = useParams<{
    monthStartDate: string;
  }>();
  if (!monthStartDate) {
    throw new Error("monthStartDate is required");
  }
  return { monthStartDate };
}
